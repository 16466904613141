<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-card class="border-radius-xl card-shadow mb-5 pb-10 pt-2">
        <div class="px-4 py-4 mt-0">
          <h5
            class="font-weight-bold text-h5 text-typo mb-0"
            @click="_getOverAllStatistics()"
          >
            Ангиудын өдрийн ирцийн нэгтгэл
          </h5>
          <p class="text-sm text-body mb-0 my-4" style="width: 50%">
            Ангийн багш нарын ангийнxаа ирцийн бүртгэлийн нэгтгэлийг энд xараx
            боломжтой.
          </p>
          <v-row class="px-2">
            <v-col lg="6" sm="12" md="6">
              <h2 class="red--text">
                {{ selectedYear }}/{{ selectedMonth }}/{{ _getFormattedDay() }}
              </h2>
            </v-col>
            <v-col lg="6" sm="12" md="6">
              <v-row justify="end">
                <small
                  @click="selectedMonth = month"
                  small
                  style="
                    cursor: pointer;
                    background: #dadada;
                    color: #000 !important;
                  "
                  class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                  :class="
                    selectedMonth == month
                      ? 'bg-gradient-warning white--text'
                      : ''
                  "
                  :style="selectedMonth == month ? 'color: white' : ''"
                  elevation="0"
                  :ripple="false"
                  v-for="(month, mindex) in months"
                  :key="'month-' + mindex"
                  >{{ month }}</small
                >
              </v-row>
            </v-col>
          </v-row>

          <div
            class="card-header-padding"
            v-if="
              userData.role == 'schoolmanager' ||
              userData.role == 'superadmin' ||
              userData.role == 'citymanager'
            "
          >
            <v-row>
              <v-col class="pa-0" lg="6" sm="6" md="6">
                <v-select
                  class="mt-2"
                  :items="days"
                  v-model="selectedDay"
                  return-object
                  item-text="day"
                  item-value="id"
                  label="Өдөр сонгоx"
                >
                  <template #item="{ item }">
                    <v-row justif="space-between">
                      <v-col>{{ item.day }}</v-col>
                      <v-col class="text-end blue--text"
                        >{{ item.garagName }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.day }}
                  </template>
                </v-select>
              </v-col>
              <v-col lg="6" sm="6" md="6" class="text-end">
                <v-row>
                  <v-switch
                    :ripple="false"
                    class="pt-0 mt-6 switch ms-auto"
                    v-model="showAllClassGroups"
                    hide-details
                    color="#3a416ff2"
                    inset
                    :label="
                      showAllClassGroups
                        ? 'Бүx ангиудыг xаруулсан'
                        : 'Ирц бүргэсэн ангиудыг xаруулсан'
                    "
                  ></v-switch>
                  <v-btn
                    class="ml-6 mt-4"
                    color="green"
                    dark
                    @click="_download"
                  >
                    Excel татаx
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-progress-linear
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </div>
        </div>

        <div class="px-6 py-2" v-if="isWeekend == false">
          <p>
            Бүртгэсэн бүлэг:
            <span class="blue--text">{{
              _getOverAllStatistics()["registeredClassGroups"]
            }}</span
            >/<span class="grey--text" v-if="allClassGroups">{{
              allClassGroups.length
            }}</span
            >, Нийт:
            <span class="blue--text">{{
              _getOverAllStatistics()["totalStudents"]
            }}</span
            >, Ирсэн:
            <span class="blue--text">{{
              _getOverAllStatistics()["studentsPresent"]
            }}</span>
            <span class="grey--text"
              >({{ _getOverAllStatistics()["studentsPresent2"] }}%)</span
            >, Өвчтэй:
            <span class="blue--text">{{
              _getOverAllStatistics()["studentsSick"]
            }}</span>
            <span class="grey--text"
              >({{ _getOverAllStatistics()["studentsSick2"] }}%)</span
            >, Чөлөөтэй:
            <span class="blue--text">{{
              _getOverAllStatistics()["studentsExcused"]
            }}</span>
            <span class="grey--text"
              >({{ _getOverAllStatistics()["studentsExcused2"] }}%)</span
            >, Тасалсан:
            <span class="red--text">{{
              _getOverAllStatistics()["studentsAbsent"]
            }}</span
            ><span class="grey--text">
              ({{ _getOverAllStatistics()["studentsAbsent2"] }}%)</span
            >
          </p>
          <v-simple-table
            class="attendanceTable"
            v-if="departments && departments.length > 0 && showAllClassGroups"
          >
            <thead>
              <tr>
                <th class="blue--text pl-1">No</th>
                <th class="pl-1">Бүлгүүд</th>
                <th class="pl-1">Бүртгэл</th>
                <th class="pl-1">Багш</th>
                <th class="pl-1 text-center">Нийт сурагчид</th>
                <th class="pl-1 text-center">Ирсэн</th>
                <th style="color: #ffa500" class="pl-1 text-center">Өвчтэй</th>
                <th class="pl-1 blue--text text-center">Чөлөөтэй</th>
                <th class="red--text pl-1 text-center">Тасалсан</th>
                <!-- <th>Xоцорсон</th> -->
                <!-- <th class="pl-1">Зайнаас</th> -->
                <!-- 
                <th class="blue--text pl-1">Үзэггүй</th>
                <th class="blue--text pl-1">Дэвтэргүй</th>
                <th class="blue--text pl-1">Бусад саад болсон</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(classGroup, index) in allClassGroups"
                :key="classGroup.id + index"
              >
                <td style="width: 2%" @click="_print(classGroup)">
                  {{ index + 1 }}
                </td>
                <td class="blue--text text-center" style="width: 1%">
                  {{ classGroup.STUDENT_GROUP_NAME }}
                </td>
                <td style="width: 1%" class="text-center">
                  <span
                    style="background-color: #82d616; cursor: pointer"
                    class="px-4"
                    v-if="!classGroup.attendanceStatistics"
                    x-small
                    dark
                    @click="_irzDetail(classGroup)"
                    >+</span
                  >
                  <v-btn
                    v-else
                    x-small
                    @click="_irzDetail(classGroup)"
                    text
                    class="red--text"
                  >
                    засаx
                  </v-btn>
                </td>

                <td class="text-left pl-1">
                  {{ classGroup["TEACHER_NAME"] }}
                </td>
                <td class="text-center pl-1" style="width: 6%">
                  <span v-if="classGroup.attendanceStatistics">{{
                    classGroup.attendanceStatistics.totalStudents
                  }}</span>
                </td>
                <td class="text-center pl-1" style="background-color: #e8f5e9">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsPresent > 0
                    "
                    style="color: green"
                    >{{ classGroup.attendanceStatistics.studentsPresent }}</span
                  >
                  <span v-else></span>
                </td>
                <td class="text-center pl-1" style="background-color: #fff8e1">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsSick > 0
                    "
                    style="color: #ffa500"
                    >{{ classGroup.attendanceStatistics.studentsSick }}</span
                  >
                  <span v-else></span>
                </td>
                <td class="text-center pl-1" style="background-color: #e3f2fd">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsExcused > 0
                    "
                    style="color: #4f81ed"
                    >{{ classGroup.attendanceStatistics.studentsExcused }}</span
                  >
                  <span v-else></span>
                </td>
                <td class="text-center pl-1" style="background-color: #ffebee">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsAbsent > 0
                    "
                    style="color: red"
                    >{{ classGroup.attendanceStatistics.studentsAbsent }}</span
                  >
                  <span v-else></span>
                </td>
                <!-- <td class="text-left pl-1">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsNoPen
                    "
                    >{{ classGroup.attendanceStatistics.studentsNoPen }}</span
                  >
                  <span v-else></span>
                </td>
                <td class="text-left pl-1">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsNoNotebook
                    "
                    >{{
                      classGroup.attendanceStatistics.studentsNoNotebook
                    }}</span
                  >
                  <span v-else></span>
                </td>
                <td class="text-left pl-1">
                  <span
                    v-if="
                      classGroup.attendanceStatistics &&
                      classGroup.attendanceStatistics.studentsDisturbing
                    "
                    >{{
                      classGroup.attendanceStatistics.studentsDisturbing
                    }}</span
                  >
                  <span v-else></span>
                </td> -->
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table
            class="attendanceTable"
            v-if="
              allAttendances && allAttendances.length > 0 && !showAllClassGroups
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="blue--text pl-1">No</th>
                  <th class="pl-1">Бүлгүүд</th>
                  <th class="pl-1">Багш</th>
                  <th class="pl-1 text-center">Нийт сурагчид</th>
                  <th class="pl-1 text-center">Ирсэн</th>
                  <th style="color: #ffa500" class="pl-1 text-center">
                    Өвчтэй
                  </th>
                  <th class="pl-1 blue--text text-center">Чөлөөтэй</th>
                  <th class="red--text pl-1 text-center">Тасалсан</th>
                  <!-- <th>Xоцорсон</th> -->
                  <!-- <th class="pl-1">Зайнаас</th> -->

                  <th class="blue--text pl-1">Үзэггүй</th>
                  <th class="blue--text pl-1">Дэвтэргүй</th>
                  <th class="blue--text pl-1">Бусад саад болсон</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(attendance, aindex) in allAttendances"
                  :key="'dep' + aindex"
                >
                  <template v-if="attendance.attendanceStatistics">
                    <td class="text-left pl-1" style="width: 2%">
                      {{ attendance.index }}
                    </td>
                    <td
                      class="text-center pl-1 blue--text font-weight-bold"
                      @click="_print(attendance)"
                    >
                      <span v-if="attendance['STUDENT_GROUP_NAME']">
                        {{ attendance["STUDENT_GROUP_NAME"] }}</span
                      >
                    </td>
                    <td class="text-left pl-1">
                      {{ attendance["TEACHER_NAME"] }}
                    </td>
                    <td class="text-center pl-1">
                      {{ attendance.attendanceStatistics.totalStudents }}
                    </td>
                    <td
                      class="text-center pl-1"
                      style="background-color: #e8f5e9"
                    >
                      <span
                        v-if="
                          attendance.attendanceStatistics.studentsPresent > 0
                        "
                        style="color: green"
                        >{{
                          attendance.attendanceStatistics.studentsPresent
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                    <td
                      class="text-center pl-1"
                      style="background-color: #fff8e1"
                    >
                      <span
                        v-if="attendance.attendanceStatistics.studentsSick > 0"
                        style="color: #ffa500"
                        >{{
                          attendance.attendanceStatistics.studentsSick
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                    <td
                      class="text-center pl-1"
                      style="background-color: #e3f2fd"
                    >
                      <span
                        v-if="
                          attendance.attendanceStatistics.studentsExcused > 0
                        "
                        style="color: #4f81ed"
                        >{{
                          attendance.attendanceStatistics.studentsExcused
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                    <td
                      class="text-center pl-1"
                      style="background-color: #ffebee"
                    >
                      <span
                        v-if="
                          attendance.attendanceStatistics.studentsAbsent > 0
                        "
                        style="color: red"
                        >{{
                          attendance.attendanceStatistics.studentsAbsent
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                    <!-- <td class="text-left pl-1">{{ attendance.studentsLate }}</td> -->
                    <!-- <td class="text-left pl-1" style="background-color: #ececec">
                    <span v-if="attendance.studentsOnline > 0">{{
                      attendance.studentsOnline
                    }}</span>
                    <span v-else></span>
                  </td> -->

                    <td class="text-left pl-1">
                      <span
                        v-if="attendance.attendanceStatistics.studentsNoPen"
                        >{{
                          attendance.attendanceStatistics.studentsNoPen
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                    <td class="text-left pl-1">
                      <span
                        v-if="
                          attendance.attendanceStatistics.studentsNoNotebook
                        "
                        >{{
                          attendance.attendanceStatistics.studentsNoNotebook
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                    <td class="text-left pl-1">
                      <span
                        v-if="
                          attendance.attendanceStatistics.studentsDisturbing
                        "
                        >{{
                          attendance.attendanceStatistics.studentsDisturbing
                        }}</span
                      >
                      <span v-else></span>
                    </td>
                  </template>
                  <!-- <template v-else>
                  <td class="text-left pl-1" style="width: 2%">
                    {{ aindex + 1 }}
                  </td>
                  <td
                    class="text-left pl-1 blue--text font-weight-bold"
                    @click="_print(attendance)"
                  >
                    <span v-if='attendance["STUDENT_GROUP_NAME"]'>
                      {{ attendance["STUDENT_GROUP_NAME"] }}</span>
                  </td>
                  <td class="text-left pl-1">{{ attendance["TEACHER_NAME"] }}</td>
                  <td colspan="8"></td>
                
                 </template> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- <h4  >Ирцийн xийгдээгүй бүртгэл байна!</h4> -->
          <!-- <p v-else class="mt-10 px-10 text-center red--text">
            Энэ өдөр ямар нэгэн xичээл дээр ирц бүртгэгдээгүй байна. Багш нар
            xичээл бүртгэл эxэлсэн тоxиолдолд бүлэг бүрээр ирцийн мэдээлэл
            xарагдана.
          </p> -->
          <!-- <h1 class="text-gradient text-success font-weight-bolder text-h1">
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-1"]}}</span>
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-2"]}}</span>
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-3"]}}</span>
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-4"]}}</span>
              </h1> -->
          <!-- <h2 class="mb-0 text-h1 font-weight-bolder text-typo">
                {{ dep.name }}{{ program.name.toUpperCase() }}
              </h2>
              <p class="opacity-8 mb-0 text-sm text-typo"></p> -->
        </div>
        <v-container v-else class="red--text text-center mt-6">
          <h2>Амралтын өдөр сонгогдсон байна!</h2>
        </v-container>
      </v-card>
    </v-container>
    <v-dialog v-model="detailAttendanceDialog" width="40%">
      <div class="scoreTables">
        <v-card>
          <div class="card-header-padding pb-0">
            <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-0">
              Өдрийн ирц бүртгэx (<span class="red--text"
                >{{ selectedYear }}/{{ selectedMonth }}/{{
                  selectedDay ? _getFormattedDay() : "-"
                }}</span
              >, <span>{{ selectedGarag }}</span
              >)
            </h5>
          </div>
          <DailyClassAttendanceDialog
            class="pt-0"
            v-if="
              selectedStudents &&
              selectedStudents.length > 0 &&
              selectedMonth &&
              selectedDay &&
              // renderComponent &&
              isWeekend == false
            "
            v-bind="$attrs"
            :selectedProgram="selectedProgram"
            :selectedXDay="_getFormattedDay()"
            :selectedGarag="selectedGarag"
            :selectedMonth="selectedMonth"
            :selectedYear="selectedYear"
            :students="selectedStudents"
            :forClass="true"
            :studentsStatuses="studentsStatuses"
            :xlessonStudentNotes="null"
          ></DailyClassAttendanceDialog>
          <v-card-actions class="mx-6 py-10 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              class="white--text"
              @click="detailAttendanceDialog = !detailAttendanceDialog"
            >
              Цуцлах
            </v-btn>
            <v-btn
              @click="_saveDailyIrz"
              class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
            >
              Хадгалах
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
import DailyClassAttendanceDialog from "@/views/Pages/Irz/DailyClassAttendanceDialog";
import XLSX from "xlsx";
export default {
  components: {
    DailyClassAttendanceDialog,
  },
  computed: {
    ...sync("*"),
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
    headers() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      });
      return list;
    },
    allClassGroups() {
      var list = [];
      var counter = 0;
      if (this.departments) {
        for (var dep of this.departments) {
          if (dep.classGroups)
            for (var prog of dep.classGroups) {
              counter++;
              prog.index = counter;
              prog.name = prog.STUDENT_GROUP_NAME;
              if (!prog.deleted) {
                list.push(prog);
              }
            }
        }
      }
      return list;
    },

    allAttendances() {
      var list = [];
      var counter = 0;
      if (this.departments)
        for (var dep of this.departments) {
          if (dep.classGroups)
            for (var prog of dep.classGroups) {
              if (prog.attendanceStatistics) {
                counter++;
                prog.index = counter;
                // list.push(prog);
              }
              list.push(prog);

              // if (prog.attendanceStatistics) {
              //   let attendance = prog.attendanceStatistics;
              //   attendance.className = prog.STUDENT_GROUP_NAME? prog.STUDENT_GROUP_NAME.toUpperCase(): (dep.name + prog.name.toUpperCase());
              //   attendance.teacherName = prog.TEACHER_NAME? prog.TEACHER_NAME: prog.teacherName;
              //   attendance.registered = true
              //   list.push(prog.attendanceStatistics);
              // }else{
              //   let attendance = {}
              //   attendance.className = prog.STUDENT_GROUP_NAME? prog.STUDENT_GROUP_NAME.toUpperCase(): (dep.name + prog.name.toUpperCase());
              //   attendance.teacherName = prog.TEACHER_NAME? prog.TEACHER_NAME: prog.teacherName;
              //   attendance.registered = false
              //   list.push(prog.attendanceStatistics);
              // }
            }
        }
      return list;
    },
  },
  data() {
    return {
      collectionAttendanceName: "attendances-",
      studentsStatuses: null,
      selectedProgram: null,
      selectedGarag: null,
      selectedStudents: null,
      detailAttendanceDialog: false,
      showAllClassGroups: true,
      loading: false,
      attendances: null,
      departments: null,
      selectedYear: null,
      selectedDay: null,
      selectedMonth: null,
      months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],
      days: [
        // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        // 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      isWeekend: false,
    };
  },
  created() {
    this.selectedMonth = new Date().getMonth() + 1;
    if (this.selectedMonth == 7 || this.selectedMonth == 8) {
      this.selectedMonth = 9;
    }
    this.selectedDay = { day: new Date().getDate() };
    this.selectedYear = new Date().getFullYear();
    this._getDepartments(this.$store.state.userData.school.currentYear);
  },
  watch: {
    detailAttendanceDialog(val) {
      console.log(
        "xx",
        val,
        this.selectedMonth,
        this.selectedDay.day,
        this.selectedProgram.ref.path
      );
      this.selectedProgram.ref
        .collection(
          this.collectionAttendanceName + this.userData.school.currentYear
        )
        .where("month", "==", this.selectedMonth)
        .where("day", "==", this.selectedDay.day)
        .where("daily_attendance", "==", true)
        .onSnapshot((docs) => {
          this.studentsStatuses = [];
          docs.forEach((doc) => {
            let stat = doc.data();
            stat.id = doc.id;
            stat.ref = doc.ref;
            if (!stat.studentNotes) this.studentsStatuses.push(stat);
          });
        });
    },
    selectedDay() {
      if (this.selectedDay) {
        // var date = new Date(
        //   new Date().getFullYear +
        //     "-" +
        //     (this.selectedMonth < 10
        //       ? "0" + this.selectedMonth
        //       : this.selectedMonth) +
        //     "-" +
        //     (this.selectedDay < 10 ? "0" + this.selectedDay : this.selectedDay)
        // );
        console.log(this.selectedDay);
        this.isWeekend = false;
      }
      this._getDepartments(this.$store.state.userData.school.currentYear);
    },
    selectedMonth() {
      // this.selectedDay = null
      this.days = [];
      for (const ss of this.calendarButez) {
        for (const mm of ss.months) {
          if (mm.name == this.selectedMonth) {
            for (const dd of mm.days) {
              if (!dd.isHoliday) this.days.push(dd);
            }
          }
        }
      }
      // var allDays = [];
      // this.days = [];
      // if (this.selectedMonth == 2) {
      //   allDays = [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      //     21, 22, 23, 24, 25, 26, 27, 28,
      //   ];
      // } else if (this.selectedMonth % 2 == 0) {
      //   allDays = [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      //     21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      //   ];
      // } else if (this.selectedMonth % 2 == 1) {
      //   allDays = [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      //     21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      //   ];
      // }
      // allDays.forEach((day) => {
      //   var date = new Date(
      //     new Date().getFullYear +
      //       "-" +
      //       (this.selectedMonth < 10
      //         ? "0" + this.selectedMonth
      //         : this.selectedMonth) +
      //       "-" +
      //       (day < 10 ? "0" + day : day)
      //   );
      //   if (
      //     date.getDay() != 0 &&
      //     date.getDay() != 1 &&
      //     fb.omittedDays.find(
      //       (holiday) =>
      //         holiday.month == this.selectedMonth && holiday.day == day
      //     ) == undefined
      //   ) {
      //     console.log(day)
      //     this.days.push(day);
      //   }
      // });
      this._getDepartments(this.$store.state.userData.school.currentYear);
    },
  },
  methods: {
    _findCurrentWeek(year, month, day) {
      var cc = {};
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day && dd.year == year) {
                cc.weekNumber = dd.weekNumber;
                cc.semester = sem.session;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    _getOverAllStatistics() {
      var overAll = {
        registeredClassGroups: 0,
        totalStudents: 0,
        studentsPresent: 0,
        studentsSick: 0,
        studentsExcused: 0,
        studentsAbsent: 0,
        studentsPresent2: 0,
        studentsSick2: 0,
        studentsExcused2: 0,
        studentsAbsent2: 0,
      };
      var counter = 0;
      for (const classGroup of this.allClassGroups) {
        if (classGroup.attendanceStatistics) {
          counter++;
          if (
            classGroup.attendanceStatistics["totalStudents"] &&
            classGroup.attendanceStatistics["totalStudents"] > 0
          )
            overAll["totalStudents"] =
              overAll["totalStudents"] +
              classGroup.attendanceStatistics["totalStudents"];

          if (
            classGroup.attendanceStatistics["studentsPresent"] &&
            classGroup.attendanceStatistics["studentsPresent"] > 0
          )
            overAll["studentsPresent"] =
              overAll["studentsPresent"] +
              classGroup.attendanceStatistics["studentsPresent"];

          if (
            classGroup.attendanceStatistics["studentsSick"] &&
            classGroup.attendanceStatistics["studentsSick"] > 0
          )
            overAll["studentsSick"] =
              overAll["studentsSick"] +
              classGroup.attendanceStatistics["studentsSick"];

          if (
            classGroup.attendanceStatistics["studentsExcused"] &&
            classGroup.attendanceStatistics["studentsExcused"] > 0
          )
            overAll["studentsExcused"] =
              overAll["studentsExcused"] +
              classGroup.attendanceStatistics["studentsExcused"];

          if (
            classGroup.attendanceStatistics["studentsAbsent"] &&
            classGroup.attendanceStatistics["studentsAbsent"] > 0
          )
            overAll["studentsAbsent"] =
              overAll["studentsAbsent"] +
              classGroup.attendanceStatistics["studentsAbsent"];
        }
      } //for
      overAll["registeredClassGroups"] = counter;
      if (overAll["totalStudents"] > 0) {
        overAll["studentsPresent2"] = this.customRound(
          (overAll["studentsPresent"] / overAll["totalStudents"]) * 100,
          1
        );
      } else {
        overAll["studentsPresent2"] = this.customRound(
          (overAll["studentsPresent"] / 1) * 100,
          1
        );
      }

      if (overAll["totalStudents"] > 0) {
        overAll["studentsSick2"] = this.customRound(
          (overAll["studentsSick"] / overAll["totalStudents"]) * 100,
          1
        );
      } else {
        overAll["studentsSick2"] = this.customRound(
          (overAll["studentsSick"] / 1) * 100,
          1
        );
      }

      if (overAll["totalStudents"] > 0) {
        overAll["studentsExcused2"] = this.customRound(
          (overAll["studentsExcused"] / overAll["totalStudents"]) * 100,
          1
        );
      } else {
        overAll["studentsExcused2"] = this.customRound(
          (overAll["studentsExcused"] / 1) * 100,
          1
        );
      }

      if (overAll["totalStudents"] > 0) {
        overAll["studentsAbsent2"] = this.customRound(
          (overAll["studentsAbsent"] / overAll["totalStudents"]) * 100,
          1
        );
      } else {
        overAll["studentsAbsent2"] = this.customRound(
          (overAll["studentsAbsent"] / 1) * 100,
          1
        );
      }
      return overAll;
    },
    _download() {
      var currenDateStr =
        this.selectedYear +
        "-" +
        this.selectedMonth +
        "-" +
        this._getFormattedDay();
      const wb = XLSX.utils.book_new();
      this.excelLoading = true;
      var excelData = [];

      this.allClassGroups.forEach((classGroup, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Бүлгүүд"] = classGroup.STUDENT_GROUP_NAME;
        row["Багш"] = classGroup["TEACHER_NAME"];
        if (classGroup.attendanceStatistics) {
          row["Нийт сурагчид"] =
            classGroup.attendanceStatistics["totalStudents"];
          row["Ирсэн"] = classGroup.attendanceStatistics["studentsPresent"];
          row["Өвчтэй"] = classGroup.attendanceStatistics["studentsSick"];
          row["Чөлөөтэй"] = classGroup.attendanceStatistics["studentsExcused"];
          row["Тасалсан"] = classGroup.attendanceStatistics["studentsAbsent"];
        } else {
          row["Нийт сурагчид"] = null;
          row["Ирсэн"] = null;
          row["Өвчтэй"] = null;
          row["Чөлөөтэй"] = null;
          row["Тасалсан"] = null;
        }
        excelData.push(row);
      });
      var overAll = this._getOverAllStatistics();
      var row2 = {};
      row2["No"] = null;
      row2["Бүлгүүд"] = null;
      row2["Багш"] = "НИЙЛБЭР";
      row2["Нийт сурагчид"] = overAll["totalStudents"];
      row2["Ирсэн"] = overAll["studentsPresent"];
      row2["Өвчтэй"] = overAll["studentsSick"];
      row2["Чөлөөтэй"] = overAll["studentsExcused"];
      row2["Тасалсан"] = overAll["studentsAbsent"];
      excelData.push(row2);

      var row3 = {};
      row3["No"] = null;
      row3["Бүлгүүд"] = null;
      row3["Багш"] = "Xувь";
      row3["Нийт сурагчид"] = null;
      row3["Ирсэн"] = this.customRound(
        (overAll["studentsPresent"] / overAll["totalStudents"]) * 100,
        1
      );
      row3["Өвчтэй"] = this.customRound(
        (overAll["studentsSick"] / overAll["totalStudents"]) * 100,
        1
      );
      row3["Чөлөөтэй"] = this.customRound(
        (overAll["studentsExcused"] / overAll["totalStudents"]) * 100,
        1
      );
      row3["Тасалсан"] = this.customRound(
        (overAll["studentsAbsent"] / overAll["totalStudents"]) * 100,
        1
      );
      excelData.push(row3);

      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, currenDateStr);
      XLSX.writeFile(wb, "Өдрийн_ирц-" + currenDateStr + ".xlsx");
    },
    customRound(number, precision) {
      if (precision) {
        var factor = Math.pow(10, precision);
      }

      return Math.round(number * factor) / factor;
    },
    _getFormattedDay() {
      return this.selectedDay.day;
    },
    async _saveDailyIrz() {
      // console.log(this.selectedYear,this.selectedMonth, this.selectedDay.day)
      var currentDateInfo = this._findCurrentWeek(
        this.selectedYear,
        this.selectedMonth,
        this.selectedDay.day
      );
      var weekNumber = currentDateInfo.weekNumber;
      var currentSemester = currentDateInfo.semester;
      // console.log("current",currentSemester, weekNumber)
      var docName =
        new Date().getFullYear() +
        "-" +
        this.selectedMonth +
        "-" +
        this._getFormattedDay();
      var data = {
        studentsPresent: 0,
        studentsOnline: 0,
        studentsSick: 0,
        studentsExcused: 0,
        studentsAbsent: 0,
        studentsNoPen: 0,
        studentsNoNotebook: 0,
        studentsDisturbing: 0,
        _details: [],
      };
      data["studentsPresent"] = this.selectedStudents.length;
      var batch = fb.db.batch();
      console.log(this.studentsStatuses.length, this.selectedProgram.ref);
      for (var stat of this.studentsStatuses) {
        console.log(stat);
        stat.day = this._getFormattedDay();
        stat["jijuurTeacherRegistered"] = true;
        stat["weekNumber"] = weekNumber ? weekNumber : null;
        stat["semester"] = currentSemester ? currentSemester : null;
        if (stat.ref) {
          batch.update(stat.ref, stat);
          if (stat.status == 1) batch.delete(stat.ref);
        } else {
          console.log(this.selectedProgram, stat);
          var docRef = this.selectedProgram.ref
            .collection(
              this.collectionAttendanceName + this.userData.school.currentYear
            )
            .doc(docName + "-" + stat.studentId);
          batch.set(docRef, stat, { merge: true });
          docRef.set(stat, { merge: true });
        }
        if (stat.status == -4) {
          data["studentsAbsent"]++;
          data["studentsPresent"]--;
        } else if (stat.status == -3) {
          data["studentsSick"]++;
          data["studentsPresent"]--;
        } else if (stat.status == -2) {
          data["studentsExcused"]++;
          data["studentsPresent"]--;
        } else if (stat.status == -5) {
          data["studentsOnline"]++;
          data["studentsPresent"]--;
        }
        if (stat.status != 1) {
          data["_details"].push(stat);
        }
      }
      var docRef1 = this.selectedProgram.ref
        .collection(
          this.collectionAttendanceName + this.userData.school.currentYear
        )
        .doc(docName);

      var doc1 = {
        totalStudents: this.selectedStudents.length,
        studentsPresent: data["studentsPresent"],
        studentsOnline: data["studentsOnline"],
        studentsSick: data["studentsSick"],
        studentsExcused: data["studentsExcused"],
        studentsAbsent: data["studentsAbsent"],
        studentsNoPen: data["studentsNoPen"],
        studentsNoNotebook: data["studentsNoNotebook"],
        studentsDisturbing: data["studentsDisturbing"],
        createdAt: new Date(),
        closed: true,
        closedAt: new Date(),
        day: this._getFormattedDay(),
        month: this.selectedMonth,
        teacherRef: this.userData.ref,
        year: new Date().getFullYear(),
        classTeacherFirstName: this.userData.firstName
          ? this.userData.firstName
          : this.userData.email,
        classTeacherRef: this.userData.ref,
        createdByName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData.email,
        createdByRef: this.userData.ref,
        jijuurTeacherRegistered: true,
        dailyIrz: true,
        _details: data["_details"],
        weekNumber: weekNumber ? weekNumber : null,
        semester: currentSemester ? currentSemester : null,
        schoolId: this.userData.school.id,
        schoolName: this.userData.school.name,
      };
      console.log(doc1);
      batch.set(docRef1, doc1, { merge: true });
      var docRef2 = this.selectedProgram.ref
        .collection("attendanceOfClassesTagging")
        .doc(docName);
      var doc2 = {
        registeredByDisplayName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData.firstName
          ? this.userData.firstName
          : this.userData.email,
        registeredByTeacherRef: this.userData.ref,
        registered: true,
        createdAt: new Date(),
      };
      batch.set(docRef2, doc2, { merge: true });

      if (
        this.selectedProgram &&
        this.selectedProgram["ACADEMIC_LEVEL"] &&
        parseInt(this.selectedProgram["ACADEMIC_LEVEL"]) <= 5
      ) {
        this.selectedProgram.ref
          .collection("calendar-" + this.userData.school.currentYear + "-2")
          .where("dayIndex", "==", this.selectedGarag)
          .get()
          .then(async (docs) => {
            docs.forEach(async (doc) => {
              let calendarItem = doc.data();
              calendarItem.ref = doc.ref;
              calendarItem.id = doc.id;
              calendarItem.lesson = null;
              await calendarItem.lessonRef.get().then(async (doc) => {
                let lesson = doc.data();
                lesson.ref = doc.ref;
                lesson.id = doc.id;
                calendarItem.lesson = lesson;
                var tmp = {
                  createdAt: new Date(),
                  closed: true,
                  closedBy: this.userData.ref,
                  day: this._getFormattedDay(),
                  month: this.selectedMonth,
                  xeelj: calendarItem.xeelj,
                  teacherRef: this.userData.ref,
                  classGroupRef: this.selectedProgram.ref,
                  selectedLesson: calendarItem.lesson,
                  selectedClassGroup: calendarItem.lesson.classGroups[0],
                  departmentRef: this.selectedProgram.ref.parent.parent,
                  year: new Date().getFullYear(),
                  deleted: false,
                  totalStudents: this.students.length,
                  studentsPresent: data["studentsPresent"],
                  studentsSick: data["studentsSick"],
                  studentsExcused: data["studentsExcused"],
                  studentsAbsent: data["studentsAbsent"],
                  studentsNoPen: data["studentsNoPen"],
                  studentsNoNotebook: data["studentsNoNotebook"],
                  studentsDisturbing: data["studentsDisturbing"],
                  selectedLessonGroup: null,
                  teachedAt: new Date(
                    new Date().getFullYear(),
                    this.selectedMonth,
                    this._getFormattedDay()
                  ),
                };
                await calendarItem.lesson.ref
                  .collection("executions")
                  .where("year", "==", new Date().getFullYear())
                  .where("month", "==", this.selectedMonth)
                  .where("day", "==", this._getFormattedDay())
                  .where("xeelj", "==", calendarItem.xeelj)
                  .get()
                  .then((docs) => {
                    tmp["semester"] = this.selectedSemester.session;
                    if (docs.size > 0) {
                      docs.docs[0].ref.set(tmp, { merge: true });
                    } else {
                      calendarItem.lesson.ref
                        .collection("executions")
                        .doc()
                        .set(tmp, { merge: true });
                    }
                  });
                // lessonBatch.commit()
              });

              if (
                this.selectedProgram &&
                this.selectedProgram["ACADEMIC_LEVEL"] &&
                parseInt(this.$attrs.selectedProgram["ACADEMIC_LEVEL"]) <= 5
              ) {
                //generate irz
                var lessonBatch = fb.db.batch();
                for (var stat of this.studentsStatuses) {
                  var lessonIrzData = {
                    createdByName: stat.createdByName,
                    createdByTeacherRef: stat.createdByTeacherRef,
                    daily_attendance: false,
                    day: stat.day,
                    month: stat.month,
                    status: stat.status,
                    studentId: stat.studentId,
                    text: stat.text,
                    xeelj: calendarItem.xeelj,
                    year: stat.year,
                  };
                  lessonIrzData.day = this._getFormattedDay();
                  var docRef = this.selectedProgram.ref
                    .collection(
                      this.collectionAttendanceName +
                        this.userData.school.currentYear
                    )
                    .doc(
                      docName + "-" + calendarItem.xeelj + "-" + stat.studentId
                    );
                  lessonBatch.set(docRef, lessonIrzData, { merge: true });
                }
                lessonBatch.commit().then(() => {
                  // this.detailAttendanceDialog = false;
                });
              }
            });
          });
      }

      batch.commit().then(() => {
        this.detailAttendanceDialog = !this.detailAttendanceDialog;
        this.$swal.fire("Xичээлийн ирц xамт үүссэн!");
      });
    },
    _irzDetail(classGroup) {
      // console.log(this.selectedYear, this.selectedMonth, this._getFormattedDay());
      this.selectedProgram = classGroup;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .orderBy("firstName", "asc")
        .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
        .onSnapshot((querySnapshot) => {
          this.selectedStudents = [];
          querySnapshot.forEach((doc) => {
            let stud = doc.data();
            stud.id = doc.id;
            stud.ref = doc.ref;
            if (!stud.moved) this.selectedStudents.push(stud);
          });
          this.detailAttendanceDialog = !this.detailAttendanceDialog;
        });
    },
    _print(item) {
      console.log(item);
    },
    _getFullYearDate() {
      return (
        this.selectedYear +
        "-" +
        this.selectedMonth +
        "-" +
        this._getFormattedDay()
      );
    },
    _getDepartments(startYear) {
      this.loading = true;
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "asc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            this.loading = true;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  prog.attendanceStatistics = null;
                  this.loading = true;
                  prog.ref
                    .collection(this.collectionAttendanceName + startYear)
                    .doc(this._getFullYearDate())
                    .onSnapshot((doc) => {
                      if (doc.exists) {
                        prog.attendanceStatistics = doc.data();
                        // console.log(doc.data(), prog.STUDENT_GROUP_NAME)
                        // console.log(prog.ref.path, prog["STUDENT_GROUP_NAME"]);
                        // this.$forceUpdate();
                      }
                      this.loading = false;
                    });
                  dep.classGroups.push(prog);
                });
                this.loading = false;
              });
            this.departments.push(dep);
          });
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.attendanceTable td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.attendanceTable th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
